<template>
    <nav id="homepage-nav" class="navbar navbar-expand-lg fixed-top py-1">
      <div class="container">
        <HeaderLogo />
        <HeaderMenu />
      </div>
    </nav>
    <slot />
    <Footer additionalClass="sticky-footer"/>
</template>
  
    